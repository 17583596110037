<template>
  <div class="pbf">
    <BrandTabGallery
      :inurl="`/photos`"
      :intags="tags"
      :brand-id="props.id"
      :category="props.type"
    /> 
  </div>
</template>


<script setup lang="ts">
 
import { TagsBrandProductGallery } from '@/types/other'

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

const tags = ref(structuredClone(TagsBrandProductGallery))


</script>



<style scoped></style>
